<template>
    <div style="margin-left:10px;min-height:600px;">

        <div style="white-space: nowrap;">
            <div style="display: inline-block; margin-right: 10px; vertical-align: middle; ">
                <span class="help-icon" @click="openHelp"> </span>
            </div>

            <div style="display: inline-block; margin-left: 10px;" v-if="entityTypes">
                <select class="select_button" v-model="selectedEntityTypeId" @change="changeEntityType($event)">
                    <option v-for="entityType in entityTypes" :key="entityType.id" :value="entityType.id">{{entityType.name}}</option>
                </select>
            </div>

            <button class="button button--success" style="margin-left:15px;" v-if="!wait && entityTypeChanged" @click="save">Сохранить</button>
            <span v-if="!wait && entityTypeChanged" style="margin-left:15px;color:#606060"><i class="fas fa-exclamation-triangle" style="color:orange"></i> Необходимо сохранить изменения</span>
        </div>


        <div id="result" v-if="!wait && entityTypeViewModel.entityType && entityTypeViewModel.rules.fields" style="margin-top:15px;">
            <label class="check option" v-if="false">
                <input class="check__input" type="checkbox" v-model="entityTypeViewModel.rules.restoreState">
                <span class="check__box"></span>
                <span class="check__text">Возвращать исходный статус после массового редактирования, при несоответствии правил </span><span style="color:red;" title="Работает в BETA режиме">&beta;</span>
            </label>
            <table class="ui-table">
                <thead>
                    <tr>
                        <th style="width:40px;">№</th>
                        <th style="width:180px;">Поле</th>
                        <th style="width:80px;">Включено</th>
                        <th style="width:240px;">Проверка</th>
                        <th>Условия</th>
                        <th style="width:240px;">Текст ошибки</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td></td>
                        <td colspan="5">
                            <!--<i class="fas fa-plus-circle"></i>-->
                            <select class="ui-select" @change="addField($event)">
                                <option disabled selected>Добавить поле</option>
                                <option v-for="field in getAvailableFieldsForRules()" :key="field.id" :value="field.id">{{field.name}}</option>
                            </select>
                        </td>
                    </tr>
                </tfoot>
                <tbody>
                    <tr v-if="entityTypeViewModel.rules.fields.length==0"><td colspan="5" style="text-align:center;font-style:italic">Здесь пусто!</td></tr>
                    <tr v-for="(ruleField,index) in entityTypeViewModel.rules.fields" :key="index" :set="field=getField(ruleField.fieldId)" class="hover-holder">
                        <td>{{index+1}}.</td>
                        <td>
                            {{field.name}} <i class="fas fa-times-circle hover-holder-block delete-field" @click="delField(index)"></i>
                        </td>
                        <td>
                            <div>
                                <label class="check option">
                                    <input class="check__input" type="checkbox" v-model="ruleField.enabled">
                                    <span class="check__box"></span>
                                </label>
                            </div>

                        </td>
                        <td>
                            <select v-model="ruleField.requirementType" class="ui-select input-requirement" style="display:block">
                                <option v-for="r in getRequirementTypesForFieldType(field.type)" :key="r.id" :value="r.id">{{r.name}}</option>
                            </select>

                            <input type="text"
                                   v-if="['string'].includes(field.type) && ['contains','notContains', 'equal', 'notEqual'].includes(ruleField.requirementType)"
                                   v-model="ruleField.fieldValues[0]"
                                   class="ui-input input-requirement"
                                   style="width:200px;" />

                            <input type="text"
                                   v-if="['digit'].includes(field.type) && ['equal', 'notEqual'].includes(ruleField.requirementType)"
                                   v-model="ruleField.fieldValues[0]"
                                   class="ui-input input-requirement"
                                   style="width:200px;" />

                            <template v-if="ruleField.requirementType === 'range'">
                                <input type="number" v-model="ruleField.fieldValues[0]"
                                       class="ui-input input-requirement"
                                       style="width:90px;" />
                                &mdash;
                                <input type="number" v-model="ruleField.fieldValues[1]"
                                       class="ui-input input-requirement"
                                       style="width:90px;" />
                            </template>




                        </td>
                        <td>
                            <div>
                                Если статус:
                                <div v-if="ruleField.states.length==0"
                                     :style="{'background-color':'#ccc', 'border':`1px solid #ccc`}"
                                     class="state_block">Любой статус</div>
                                <div v-for="stateId in ruleField.states"
                                     :key="stateId"
                                     :style="{'background-color':getStateColor(stateId), 'border':`1px solid ${getStateColor(stateId)}`}"
                                     class="state_block"
                                     :title="getStateName(stateId)">
                                    <span class="d" @click="delFilterState(ruleField, stateId)">×</span> <span class="n">{{getStateName(stateId)}}</span>
                                </div>
                                <select class="ui-select select-plus" @change="addFilterState(ruleField, $event)" v-if="getAvailableFilterStatesForField(ruleField).length>0">
                                    <option selected disabled></option>
                                    <option v-for="state in getAvailableFilterStatesForField(ruleField)" :key="state.id" :value="state.id">{{state.name}}</option>
                                </select>
                            </div>
                            <div>
                                и заполнено поле:
                                <div v-if="ruleField.fields.length==0"
                                     class="field_block"><span class="n">Не выбрано</span></div>
                                <div v-for="fieldId in ruleField.fields"
                                     :key="fieldId"
                                     class="field_block"
                                     :title="getFieldName(fieldId)">
                                    <span class="d" @click="delFilterField(ruleField, fieldId)">×</span> <span class="n">{{getFieldName(fieldId)}}</span>
                                </div>
                                <select class="ui-select select-plus" @change="addFilterField(ruleField, $event)" v-if="getAvailableFilterFieldsForField(ruleField).length>0">
                                    <option selected disabled></option>
                                    <option v-for="field in getAvailableFilterFieldsForField(ruleField)" :key="field.id" :value="field.id">{{field.name}}</option>
                                </select>
                            </div>

                        </td>
                        <td>
                            <input type="text"
                                   v-model="ruleField.errorText"
                                   class="ui-input"
                                   style="width:200px;" 
                                   placeholder="Необязательное поле"/>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>

        <div v-if="wait" class="wait-row">
            <img src="/img/wait.gif" />
        </div>
        <modal widthModal="300" @close="nop" v-if="showModalSave">
            <div style="padding:15px;">
                <h1>Данные не сохранены!</h1>
                <div style="margin-bottom:15px;">
                    <button class="button button" @click="closeModalSave(false)">Не сохранять</button>
                    <button class="button button--success" @click="closeModalSave(true)" style="margin-left:10px;">Сохранять</button>
                </div>
            </div>
            
        </modal>
    </div>
</template>

<script>
    //global.jquery = global.jQuery = global.$ = require('jquery');

    import axios from 'axios';
    import modal from './shared/modal.vue';
    //import Select2 from 'v-select2-component';


    export default {
        name: 'Report',
        components: {
         //   Select2
            modal,
        },
        data: () => ({
            showModalSave: false,
            wait: false,
            waitText: null,
            renderTable: true,
            entityTypes: [],
            selectedEntityTypeId: null,
            entityTypeViewModel: {
                rules: {},
                entityType: {}
            },
            entityTypeChanged: false,

        }),
        watch: {
            entityTypeViewModel: {
                handler: function() {
                    //window.console.log('changed');
                    this.entityTypeChanged = true;
                    
                },
                deep: true
            }
            
        },
        created: function () {
            let that = this;

            this.$session.onSessionReady().then(() => that.init());
        },

        methods: {
            nop: function () {

            },
            getStateColor: function (stateId) {

                if (!stateId) {
                    return;
                }
                var state = this.entityTypeViewModel.entityType.states.find(x => x.id === stateId);

                if (state) {
                    return state.color;
                } else {
                    return '#ccc';
                }
                

            },
            getStateName: function (stateId) {
                if (!stateId) {
                    return;
                }

                var state = this.entityTypeViewModel.entityType.states.find(x => x.id === stateId);

                if (state) {
                    return state.name;
                } else {
                    return '!!!Статус удален';
                }

            },
            getAvailableFilterStatesForField: function (ruleField) {
                return this.entityTypeViewModel.entityType.states.filter(x => !ruleField.states.includes(x.id));
            },
            delFilterState: function (ruleField, stateId) {
                const index = ruleField.states.indexOf(stateId);
                if (index > -1) {
                    ruleField.states.splice(index, 1);
                }
            },
            delField: function (index) {
                this.entityTypeViewModel.rules.fields.splice(index, 1);
            },
            addFilterState: function (ruleField, event) {
                if (event.target.value) {
                    ruleField.states.push(event.target.value);
                }
                
                event.target.options.selectedIndex = 0;
            },
            getFieldName: function (fieldId) {
                if (!fieldId) {
                    return;
                }

                var field = this.entityTypeViewModel.entityType.fields.find(x => x.id === fieldId);

                if (field) {
                    return field.name;
                } else {
                    return '!!!Поле удалено';
                }

            },
            getAvailableFieldsForRules: function () {//
                return this.entityTypeViewModel.entityType.fields
                    .filter(x => x.useForRule);
            },
            getAvailableFilterFieldsForField: function (ruleField) {//
                return this.entityTypeViewModel.entityType.fields
                    .filter(x => x.useForCondition && !ruleField.fields.includes(x.id));
            },
            delFilterField: function (ruleField, fieldId) {
                const index = ruleField.fields.indexOf(fieldId);
                if (index > -1) {
                    ruleField.fields.splice(index, 1);
                }
            },
            addFilterField: function (ruleField, event) {
                if (event.target.value) {
                    ruleField.fields.push(event.target.value);
                }

                event.target.options.selectedIndex = 0;
       
            },
            getRequirementTypesForFieldType: function (type) {
                var result = [];
                //result.push({ id: "none", name: "Выберете ..." });

                if (type === 'boolean') {
                    result.push({ id: "notEmpty", name: "Да" });
                    result.push({ id: "empty", name: "Нет" });
                } else if(type) {
                    result.push({ id: "notEmpty", name: "Непустое" });
                    result.push({ id: "empty", name: "Пустое" });
                    
                    
                }
                

                if (type === 'digit') {
                    result.push({ id: "range", name: "Диапазон" });
                    result.push({ id: "equal", name: "Равно" });
                    result.push({ id: "notEqual", name: "Не равно" });
                }

                if (type === 'string') {
                    result.push({ id: "contains", name: "Содержит" });
                    result.push({ id: "notContains", name: "Не содержит" });
                    result.push({ id: "equal", name: "Равно" });
                    result.push({ id: "notEqual", name: "Не равно" });
                }

                return result;
            },
            getField: function (fieldId) {
                var field = this.entityTypeViewModel.entityType.fields.find(x => x.id == fieldId);

                if (!field) {
                    field = {
                        id: fieldId,
                        name: fieldId,
                        type: null,
                    };
                }

                return field;
            },
            addField: function (event) {
                this.entityTypeViewModel.rules.fields.push({
                    fieldId: event.target.value,
                    enabled: true,
                    requirementType: "notEmpty",
                    fieldValues: [],
                    states: [],
                    fields: [],
                    textError:''
                });

                event.target.options.selectedIndex = 0;
            },

            save: async function () {
                await axios.put(`/api/setting`, this.entityTypeViewModel.rules);

                this.$nextTick(() => {
                    this.entityTypeChanged = false;
                });
            },

            closeModalSave: async function (saveRules) {
                if (saveRules) {
                    await this.save();
                }

                this.showModalSave = false;

                await this.selectEntityType();


            },

            changeEntityType: function () {
                if (!this.entityTypeChanged) {
                    this.selectEntityType();
                } else {
                    this.showModalSave = true;

                }
            },
            selectEntityType: async function () {
                this.enableWait();

                this.entityTypeViewModel.rules = {};
                this.entityTypeViewModel.entityType = {};
                

                if (this.selectedEntityTypeId) {
                    const res = await axios(`/api/setting/entitytypes/${this.selectedEntityTypeId}`);

                    this.entityTypeViewModel.rules = res.data.rules;
                    this.entityTypeViewModel.entityType = res.data.entityType;

                    this.entityTypeViewModel.entityType.fields = this.entityTypeViewModel.entityType.fields
                        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                }


                if (!this.entityTypeViewModel.rules.fields) {
                    this.entityTypeViewModel.rules.fields = [];
                }

                this.$nextTick(() => {
                    this.entityTypeChanged = false;
                });

                


                this.disableWait();

            },
            getEntityTypes: async function () {
                const res = await axios(`/api/setting/entitytypes`);

                this.entityTypes = res.data;
                this.selectedEntityTypeId = this.entityTypes[0].id;

                await this.selectEntityType();
            },
            init: async function () {

                this.enableWait();
                if (this.$session.isSessionOk) {

                    this.isAdmin = this.$session.isAdmin();

                    if (!this.$session.isAdmin()) {
                        this.$router.push('/app/403');
                    }

                    this.getEntityTypes();
                }

                this.disableWait();
            },

            enableWait: function () {
                this.wait = true;
            },
            disableWait: function () {
                this.wait = false;
            },
            openLink: function (url) {
                window.open(url, '_blank');
            },
            openHelp: function () {
                this.openLink("https://doc.sklad24.online/msvalidator");
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .select_button {
        list-style-type: none;
        padding: 5px 5px 5px 11px;
        font-size: 12px;
        color: #222222;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    }

    .fa-plus-circle {
        margin-right: 5px;
        color: #3b99fc
    }

    .state_block {
        display: inline-block;
        padding: 2px 7px 2px 7px;
        border-radius: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        color: white;
    }

        .state_block span {
            color: white;
            border-bottom: 0px;
            vertical-align:top;
        }

    .state_block .n {
        max-width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }

        .state_block .d {
            cursor: pointer;
            font-weight:600;
        }


    .field_block {
        display: inline-block;
        padding: 2px 7px 2px 7px;
        border-radius: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        background-color: #E4F1FA;
        border: solid 1px #186999;
    }

        .field_block span {
            color: #186999;
            border-bottom: 0px;
            vertical-align: top;
        }

        .field_block .n {
            max-width: 90px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

        .field_block .d {
            cursor: pointer;
            font-weight: 600;
        }


    .select-plus {
        width: 8px;
        border: 0px;
        background: url(plus2.png) no-repeat right white;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        padding: 2px;
        color: transparent;
    }   
    .select-plus option{
        color: black;
    }

    select:focus, input:focus {
        outline: none;
        border-radius: 0px;
    }

    .ui-table td{
        vertical-align:top;
    }

    .ui-table tbody td:nth-child(3) div{
        padding-left:20px;
    }

    .hover-holder-block{
        display:none;
    }

    .hover-holder:hover .hover-holder-block {
        display: inline-block;
    }

    .delete-field{
        color:#ccc;
        cursor:pointer;
    }

    .input-requirement {
        width: 100px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px dotted #186999;
        min-width:50px;
    }

        .input-requirement::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
</style>

