<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click.self="$emit('close')">
                <div class="modal-container" :style="{width: widthModal>0 ? widthModal+'px': '99vw'}">
                    <div class="modal-body">
                        <slot></slot>

                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        props: {
            widthModal: String
        },

        mounted: function () {
            //window.console.log(this.calendar);
            this.$emit('open');
        },
        methods: {
        }
    };
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        padding-top: 10px;
    }

    .modal-container {
        /* width: 500px;*/
        margin: 0px auto;
        padding: 0px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }


    .modal-body {
        margin: 0 0;
    }

    .modal-default-button {
        display: block;
        margin-top: 1rem;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
</style>