<template><div></div></template>
<script>
    import axios from 'axios';

    export default {
        name: 'Widget',
        components: {

        },
        data: () => ({
            entityType: null,
            rulesCollections: [],
            messageOutId: 1,
            fetchLock: false,
            inited: false,
        }),
        mounted: function () {
            //let that = this;
            this.listenEvents();
            
        },
        computed: {

        },
        methods: {
            
            listenEvents: function () {

                window.addEventListener("message", async (event) => {
                    let that = this;
                    window.console.debug(message);
                    var message = event.data;

                    if (!message.name) {
                        return;
                    }

                    switch (message.name) {
                        case 'Open':
                            
                            this.$session.onSessionReady()
                                .then(async () => await that.getRule(message, true));
                            
                            break;
                        case 'Change':
                            var rule = await this.getRule(message);

                            if (rule) {
                                this.validateEntity(message, rule);
                                
                            } else {
                                this.sendValidationFeedback(message, true, null);
                            }

                            

                            
                            break;
                    }

                }, false);
            },
            sendValidationFeedback: function (message, isValid, errorText) {
                
                if (!isValid) {
                    window.console.log(`sendValidationFeedback isValid=${isValid} error=${errorText}`);
                    
                }
                

                window.parent.postMessage({
                    "name": "ValidationFeedback",
                    "messageId": this.messageOutId++,
                    "correlationId": message.messageId,
                    "valid": isValid,
                    "message": errorText
                }, '*');
            },
            getRule: async function (message, force=false) {
                var entityType = this.getEntityTypeFromMessage(message);

                this.entityType = entityType;

                var rule = this.rulesCollections[entityType];

                if (!force
                    && rule) {
                    return rule;
                }

                if (this.fetchLock) {
                    let that = this;
                    return (new Promise(resolve => setTimeout(resolve, 100)))
                        .then(() => {
                            return that.getRule(message);
                        });
                }

                try {
                    this.fetchLock = true;

                    const res = await axios(`/api/setting/entitytypes/${entityType}`, {
                        timeout: 3000
                    });

                    if (res.data) {
                        res.data.updated = new Date();

                        this.rulesCollections[entityType] = res.data;
                        rule = res.data;
                    }

                } catch (e) {
                    window.console.log(e);
                    rule = null;
                }

                window.console.debug(this.rulesCollections[this.entityType]);

                this.fetchLock = false;
                this.inited = true;
                return rule;

            },
            getEntityTypeFromMessage: function (message) {
                var extensionPoint = message.extensionPoint;

                return extensionPoint.split('.')[1];
            },
            getIdFromHref: function (str) {
                if (str) {
                    return str.split('/').reverse()[0].split('?')[0];
                }

                return null;
            },
            validateEntity: function (message, rule) {
                var properties = this.parseObject(message.objectState);
                window.console.debug(properties);
                var stateId = this.getIdFromHref(properties['state.meta.href']);

                for (var i = 0; i < rule.rules.fields.length; i++) {
                    var field = rule.rules.fields[i];

                    //skip not enabled
                    if (!field.enabled) {
                        continue;
                    }

                    //skip if not status
                    if (stateId 
                        && field.states.length > 0
                        && !field.states.includes(stateId)) {
                        continue;
                    }

                    //skip if fields notEmpty
                    if (field.fields.length > 0
                        && !this.validateEntityFieldsNotEmpty(properties, field.fields)) {
                        continue;
                    }

                    if (!this.validateEntityField(properties, field)) {
                        var fieldName = rule.entityType.fields.find(x => x.id === field.fieldId).name;

                        if (!fieldName) {
                            fieldName = field.fieldId;
                        }

                        var error = this.validateEntityFieldError(field, fieldName, i+1);
                        
                        this.sendValidationFeedback(message, false, error);

                        

                        return;
                    }
                }

                this.sendValidationFeedback(message, true, null);
            },
            validateEntityFieldError: function (field, fieldName, fieldNumber) {
                var error = '';

                switch (field.requirementType) {
                    case 'notEmpty':
                        error = 'должно быть заполненным';
                        break;
                    case 'empty':
                        error = 'должно быть не заполненным';
                        break;
                    case 'contains':
                        error = `должно содержать '${field.fieldValues[0]}'`;
                        break;
                    case 'notContains':
                        error = `должно не содержать '${field.fieldValues[0]}'`;
                        break;
                    case 'equal':
                        error = `должно быть равным '${field.fieldValues[0]}'`;
                        break;
                    case 'notEqual':
                        error = `должно быть не равным '${field.fieldValues[0]}'`;
                        break;
                    case 'range':
                        var from = field.fieldValues[0], to = field.fieldValues[1];
                        if (!from) from = '';
                        if (!to) to = '';
                        error = `должно быть в диапазоне '${from}' - '${to}'`;
                        break;
                }

                if (field.errorText && field.errorText !== '') {
                    return `[${fieldNumber}] ${field.errorText}`;
                }

                return `[${fieldNumber}] Поле '${fieldName}' ${error}`;
            },
            validateEntityField: function (properties, field) {
                
                var value = properties[field.fieldId];
                var type = typeof value;
                
                //BOOLEAN
                if (type === 'boolean' && field.requirementType === 'notEmpty' && value ) {
                    return true;
                }
                else if (type === 'boolean' && field.requirementType === 'empty' && !value ) {
                    return true;
                }
                //STRING
                else if (type === 'string' && field.requirementType === 'notEmpty' && !this.isEmptyOrSpaces(value)) {
                    return true;
                }
                else if (type === 'string' && field.requirementType === 'empty' && this.isEmptyOrSpaces(value)) {
                    return true;
                }
                else if (type === 'string' && field.requirementType === 'contains' && this.stringContains(value, field.fieldValues[0])) {
                    return true;
                }
                else if (type === 'string' && field.requirementType === 'notContains' && !this.stringContains(value, field.fieldValues[0])) {
                    return true;
                }
                else if (type === 'string' && field.requirementType === 'equal' && this.stringOrNumberEquals(value, field.fieldValues[0], properties)) {
                    return true;
                }
                else if (type === 'string' && field.requirementType === 'notEqual' && !this.stringOrNumberEquals(value, field.fieldValues[0], properties)) {
                    return true;
                }
                //NUMBER
                else if (type === 'number' && field.requirementType === 'notEmpty' && !this.isEmptyOrSpaces(value)) {
                    return true;
                }
                else if (type === 'number' && field.requirementType === 'empty' && this.isEmptyOrSpaces(value)) {
                    return true;
                }
                else if (type === 'number' && field.requirementType === 'equal' && this.stringOrNumberEquals(value, field.fieldValues[0], properties)) {
                    return true;
                }
                else if (type === 'number' && field.requirementType === 'notEqual' && !this.stringOrNumberEquals(value, field.fieldValues[0], properties)) {
                    return true;
                }
                else if (type === 'number' && field.requirementType === 'range') {
                    

                    var from = field.fieldValues[0];
                    if (from) {
                        from = parseInt(from);
                    } else {
                        from = Number.MIN_SAFE_INTEGER;
                    }

                    var to = field.fieldValues[1];
                    if (to) {
                        to = parseInt(to);
                    } else {
                        to = Number.MAX_SAFE_INTEGER;
                    }

                    window.console.debug(`RANGE from=${from} to=${to} value=${value}`);

                    if (value >= from && to >= value) {
                        return true;
                    }

                    //if (from && from <= value && to && to >= value) {
                    //    return true;
                    //}
                    //if (from && from <= value && !to) {
                    //    return true;
                    //}
                    //if (to && to >= value && !from) {
                    //    return true;
                    //}
                }
                //NULL
                else if (type === 'object' && field.requirementType === 'notEmpty' && value) {
                    return true;
                }
                else if (type === 'object' && field.requirementType === 'empty' && !value) {
                    return true;
                }
                //UNDEFINED
                else if (type === 'undefined' && field.requirementType === 'empty') {
                    return true;
                }
                else if (type === 'undefined' && field.requirementType === 'notContains') {
                    return true;
                }

                window.console.log(`validateEntityField[${field.fieldId}]: requirementType=${field.requirementType} type=${type} value=${value} FALSE!`);

                return false;
            },
            stringOrNumberEquals: function (str1, str2, properties) {

                let strCompare = str2;
                if (str2.startsWith('$.')) {
                    var field = str2.substring(2);

                    if (!(field in properties)) {
                        return false;
                    }

                    strCompare = properties[field];
                }

                let result = str1.toString().toLowerCase() === strCompare.toString().toLowerCase();

                //window.console.debug(`stringOrNumberEquals str1=${str1.toString().toLowerCase()} strCompare=${strCompare.toString().toLowerCase()} result=${result}`);

                return result;
            },
            stringContains: function (str1, str2) {
                if (!str1 || !str2) {
                    return false;
                }
                return str1.toLowerCase().includes(str2.toLowerCase());
            },
            validateEntityFieldsNotEmpty: function (properties, fields) {
                for (var i = 0; i < fields.length; i++) {
                    var field = fields[i];
                    var value = properties[field];

                    switch (typeof value) {
                        case 'boolean':
                            if (value) return true;
                            break
                        case 'string':
                            if (!this.isEmptyOrSpaces(value)) return true;
                            break;
                        case 'object':
                            if (value) return true;
                            break;

                    }
                }

                return false;
            },
            isEmptyOrSpaces: function (str) {
                if (typeof (str) === 'string') {
                    return str === null || str.match(/^ *$/) !== null;
                } else if (str) {
                    return true;
                }

                return false;
                
            },
            parseAttributes: function (element, properties) {
                
                for (var key of Object.keys(element)) {
                    if (!Object.hasOwn(element[key],'id')
                        || !Object.hasOwn(element[key], 'value')
                        || element[key].value === null) {
                        continue;
                    }

                    var id = element[key].id;
                    var value = element[key].value;


                    if (typeof (value) === 'object' && Object.hasOwn(value,'name')) {
                        value = value.name;
                    }

                    var propertyId = `attributes.${id}`;

                    properties[propertyId] = value;

                    var field = this.rulesCollections[this.entityType]?.entityType.fields?.find(x => x.id === propertyId);

                    window.console.debug(`key=${propertyId} name=${field.name}`);

                    if (field) {
                        properties[`attributes.${field.name}`] = value;
                    }
                }


            },
            parseObject: function (element, prefix, properties) {
                if (!element) {
                    return;
                }
                if (!properties) {
                    properties = [];
                }
                if (!prefix) {
                    prefix = "";
                }

                var moneyFields = ["sum", "price", "cost", "overhead", "salesAmount", "bonusPoints", "balance", "averageReceipt", "profit"];
                
                for (var key of Object.keys(element)) {
                    var value = element[key];

                    switch (typeof value) {
                        case 'boolean':
                        case 'string':
                            properties[`${prefix}${key}`] = value;
                            break;
                        case 'number':
                            if (moneyFields.includes(key)
                                || key.toLowerCase().endsWith('sum')) {
                                value = value / 100;
                            }
                            properties[`${prefix}${key}`] = value;
                            break;
                        case 'object':
                            if (Array.isArray(value) && key ==='attributes') {
                                this.parseAttributes(value, properties);
                            } else {
                                this.parseObject(value, `${prefix}${key}.`, properties);
                            }
                            
                            break;
                    }

                }

                if (Object.hasOwn(element, 'reservedSum')
                    && Object.hasOwn(element, 'sum')) {
                    properties['reserved'] = element.reservedSum == element.sum;
                }

                if (Object.hasOwn(element, 'payedSum')
                    && Object.hasOwn(element, 'sum')) {
                    properties['payedPercent'] = element.payedSum / element.sum * 100;
                }

                if (Object.hasOwn(element, 'shippedSum')
                    && Object.hasOwn(element, 'sum')) {
                    properties['shippedPercent'] = element.shippedSum / element.sum * 100;
                }

                return properties;
            }
        }
    };
</script>

<style scoped>
</style>