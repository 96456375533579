<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {

    };
</script>

<style>
    @import '~@fortawesome/fontawesome-free/css/all.css';
    @import '../public/css/uikit.min.css';
    /**/

    a.link {
        font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
        font-size: 12px;
        color: #1e6a97;
        border-bottom: 1px dotted;
        text-decoration: none;
    }

    a.link-gray {
        font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
        font-size: 12px;
        color: dimgray;
        border-bottom: 1px dotted;
        text-decoration: none;
    }

    a.link-red {
        font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
        font-size: 12px;
        color: red;
        border-bottom: 1px dotted;
        text-decoration: none;
    }

    .ui-textarea {
        min-width: 250px;
        font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
        font-size: 12px;
        border: solid 1px #bfbfbf;
        padding: 0 5px;
    }

    .form-group label {
        min-width: 120px;
        font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
        font-size: 12px;
    }

    .label-in {
        min-width: 40px !important;
    }

    span {
        font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
        font-size: 12px;
    }

    .form-group * {
        vertical-align: top;
    }

    .form-group {
        margin-bottom: 2px !important;
    }

    .help-icon {
        background: url("/img/help.svg");
        width: 15px;
        height: 15px;
        display: inline-block;
        cursor: pointer
    }

    .wait-row {
        text-align: center;
    }
</style>

