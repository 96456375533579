<template>
    <div></div>
</template>

<script>
    import axios from 'axios';

    export default {
        contextKey: null,
        initialization: true,
        isSessionOk: false,
        token: {},
        init(contextKey) {
            this.contextKey = contextKey;

            this.getTokenByContextKey();
        },
        isAdmin: function () {
            if (!Object.prototype.hasOwnProperty.call(this.token, 'scopes')) {
                return false;
            }
            return this.token.scopes.includes('admin.view.all');
        },
        validateStatus: function (status) {
            return status >= 200 && status < 600; // default
        },

        getTokenByContextKey() {

            if (this.contextKey == null || this.contextKey.length === 0) {
                return;
            }

            let that = this;
            axios.get(`/api/token?contextKey=${that.contextKey}`,
                {
                    validateStatus: that.validateStatus
                }
            )
                .then(function (response) {
                    if (response.status == 200) {
                        that.token = response.data;
                        that.registerTokenValidator();
                        that.isSessionOk = true;
                    } else {
                        window.console.log(`context not valid status=${response.status}`);
                        that.isSessionOk = false;
                    }

                    that.initialization = false;
                });
        },
        tokenValidatorId: null,
        tokenRefreshId: null,
        tokenIsExist() {
            return Object.prototype.hasOwnProperty.call(this.token, 'jwt');
        },
        registerTokenValidator() {

            //every 10 minutes
            this.tokenValidatorId = setInterval(() => { this.tokenValidator(); }, 30 * 60 * 1000);

            //every 24 hours
            this.tokenRefreshId = setInterval(() => { this.tokenRefresh(); }, 24 * 60 * 60 * 1000);
        },

        tokenValidator() {

            if (!this.tokenIsExist()) {
                clearInterval(this.tokenValidatorId);
                return;
            }

            let that = this;

            axios.get('/api/token/validate',
                {
                    validateStatus: that.validateStatus
                }
            )
                .then(function (response) {

                    if (response.status != 200) {
                        that.token = {};
                        that.isSessionOk = false;
                    }
                });

        },
        tokenRefresh() {

            if (!this.tokenIsExist()) {
                clearInterval(this.tokenRefreshId);
                return;
            }

            let that = this;

            axios.post('/api/token/refresh', {},
                {
                    validateStatus: that.validateStatus
                }
            )
                .then(function (response) {
                    if (response.status == 200) {
                        that.token = response.data;
                    } else {
                        that.token = {};
                        that.isSessionOk = false;
                    }
                });
        },
        sleep() {
            return;
        },
        async onSessionReady() {
            if (this.initialization) {
                let that = this;
                return (new Promise(resolve => setTimeout(resolve, 50)))
                    .then(() => {
                        return that.onSessionReady();
                    });
            } else {
                return;
            }
        },
        getJwtToken() {
            if (this.tokenIsExist()) {
                return this.token.jwt;
            } else {
                return null;
            }
        }
    }
</script>

<style scoped>
</style>