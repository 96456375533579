import Vue from 'vue';
import axios from 'axios';
import VueRouter from 'vue-router';

window.$ = require('jquery')
window.JQuery = require('jquery')

Vue.use(VueRouter);
/*
import 'bootstrap';



import Error from './components/Error.vue';


*/
import Error from './Error.vue';
import Error403 from './Error403.vue';
import Admin from './Admin.vue';
import Widget from './Widget.vue';

import session from './shared/session.vue';
import App from './App.vue';


Vue.config.productionTip = true;



var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/app/admin',
            name: 'admin',
            component: Admin
        },
        {
            path: '/app/widget',
            name: 'widget',
            component: Widget
        },
        {
            path: '/app/error',
            name: 'error',
            component: Error
        },
        {
            path: '/app/403',
            name: 'Error403',
            component: Error403
        }
    ]
});

new Vue({
    router,
    render: h => h(App),
    beforeCreate: function () {

        Vue.prototype.$session = session;

        this.$session.init(this.$route.query.contextKey);

        axios.interceptors.request.use(
            req => {
                const token = this.$session.getJwtToken();
                if (token) {
                    req.headers['Authorization'] = 'Bearer ' + token;
                }
                // config.headers['Content-Type'] = 'application/json';
                return req;
            },
            error => {
                Promise.reject(error)
            });
    }
}).$mount('#app');

