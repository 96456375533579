<template>
    <div style="padding:20px;">
        <h3>К сожалению, необходимы права администратора!</h3>
    </div>
</template>

<script>
    export default {

    };
</script>

<style scoped>
</style>